<template>
  <el-dialog
    :visible="dialogVisible"
    @close="handleDialogClose"
    :close-on-click-modal="false"
    width= '50%'
    :title="title"
    style="text-align: left;"
  >
    <div>
       <!-- :rules="[{ required: true, validator:this.checkEmailsValidation, trigger: ['blur'] }]" -->
      <el-form :model="sendEmailDialogForm" ref="sendEmailDialogForm">
        <p>(Multiple emails to be separated by semicolon)</p>
        <el-form-item
          prop="emails"
          label="Email"
          :rules="[{ required: true, validator:this.checkEmailsValidation, trigger: ['blur'] }]"
        >
          <el-input
            type="textarea"
            :rows="2"
            v-model="sendEmailDialogForm.emails">
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="el-dialog__footer">
      <el-button
        outline
        round
        @click="handleDialogClose"
      >
        Cancel
      </el-button>
      <el-button
        round
        type="primary"
        :loading="sendLoading"
        @click="handleSendEmail"
      >
        Send
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'DialogSendReminderEmail',
  props: {
    dialogVisible: Boolean,
    title: String
  },
  data () {
    return {
      sendLoading: false,
      sendEmailDialogForm: {
        emails: ''
      }
    }
  },
  watch: {
    dialogVisible (newVal, oldVal) {
      if (newVal) {
        this.sendEmailDialogForm.emails = `${this.$attrs.defaultEmail};`
      }
    }
  },
  methods: {
    resetFields () {
      Object.assign(this.$data, this.$options.data())
    },
    checkEmailsValidation (rule, value, callback) {
      const regex = /^[A-Za-z\d]+([+-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
      const emails = value.replace(/\s*/g, '').split(';').filter(Boolean)
      let validEmailStrs = ''
      let errEmailStrs = ''
      if (emails.length > 0) {
        emails.forEach(email => {
          if (!regex.test(email)) {
            errEmailStrs = errEmailStrs !== '' ? `${errEmailStrs};${email}` : `${email}`
            const msg = `Please enter valid email addresses. ${errEmailStrs} is not a valid email address.`
            // callback(new Error('Please enter valid email addresses.'))
            callback(new Error(msg))
          } else {
            validEmailStrs = validEmailStrs !== '' ? `${validEmailStrs};${email}` : `${email}`
          }
        })
      } else {
        callback(new Error('Please enter valid email addresses.'))
      }
      this.sendEmailDialogForm.emails = validEmailStrs !== '' ? `${validEmailStrs}${errEmailStrs ? ';' : ''}${errEmailStrs}` : `${errEmailStrs}`
      if (emails.length === validEmailStrs.split(';').length) {
      }
      callback()
    },
    handleDialogClose (isSent) {
      this.sendLoading = true
      this.$emit('handleSendReminderDialogClose')
    },
    handleSendEmail () {
      this.$refs.sendEmailDialogForm.validate((valid) => {
        if (valid) {
          this.sendLoading = true
          const payload = {
            to: this.sendEmailDialogForm.emails.split(';').filter(str => !!str)
          }
          this.$emit('handleSendEmail', payload)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
