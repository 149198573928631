var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticStyle: { "text-align": "left" },
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "50%",
        title: _vm.title,
      },
      on: { close: _vm.handleDialogClose },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "sendEmailDialogForm",
              attrs: { model: _vm.sendEmailDialogForm },
            },
            [
              _c("p", [
                _vm._v("(Multiple emails to be separated by semicolon)"),
              ]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "emails",
                    label: "Email",
                    rules: [
                      {
                        required: true,
                        validator: this.checkEmailsValidation,
                        trigger: ["blur"],
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 2 },
                    model: {
                      value: _vm.sendEmailDialogForm.emails,
                      callback: function ($$v) {
                        _vm.$set(_vm.sendEmailDialogForm, "emails", $$v)
                      },
                      expression: "sendEmailDialogForm.emails",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "el-dialog__footer" },
        [
          _c(
            "el-button",
            {
              attrs: { outline: "", round: "" },
              on: { click: _vm.handleDialogClose },
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "el-button",
            {
              attrs: { round: "", type: "primary", loading: _vm.sendLoading },
              on: { click: _vm.handleSendEmail },
            },
            [_vm._v(" Send ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }